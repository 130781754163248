<template>
    <div class="authenticationPage">
        <div class="top-title" v-if="flag == 1 || flag == 2 || resultInfo || isFace">企业实名认证</div>
        <div class="top-title" v-if="flag == 3">授权成功</div>
        <div class="top-title" v-if="flag == 4">授权失败</div>
        <div class="top-title" v-if="resultStatus">签署权限授权</div>
        <!-- <div class="content" v-if="flag == 1">
            <img class="img2" src="@/assets/submit-icon.png">
            <span style="margin-top:20px;">恭喜您,成功完成企业认证!</span>
        </div> -->
        <div class="content" v-if="flag == 2 || isFace">
            <img class="img1" src="@/assets/toexamine-icon.png">
            <span class="span1">恭喜您提交成功</span>
            <span class="span2">您的企业实名认证正在审核中～</span>
            <span class="span2">请耐心等待,1~3个工作日完成审核</span>
        </div>
        <div class="content" v-if="flag == 3">
            <img class="img1" src="@/assets/toexamine-icon.png">
            <span class="span1" style="margin-top:20px;">您已授权成功</span>
        </div>
        <div class="content" v-if="flag == 4">
            <img class="img1" src="@/assets/jujueshouquan.png">
            <span class="span1" style="margin-top:20px;">您已拒绝授权</span>
        </div>
        <div class="content" v-if="resultStatus && resultStatus.approvalResult == 1 && !flag">
            <img class="img1" src="@/assets/toexamine-icon.png">
            <span class="span1" style="margin-top:20px;">您的授权申请已通过</span>
             <div class="info-flex1" v-if="resultStatus">
                <span @click="goHome">去签合同</span>
            </div>
            <div class="title">签署权限</div>
            <div class="info-item1">
                <span>签署企业合同:</span>
                <span>{{resultStatus.authorityTypeShow}}</span>
            </div>
            <div class="info-item1">
                <span>授权有效期:</span>
                <span v-if="resultStatus.endTime">{{resultStatus.startTime}}~{{resultStatus.endTime}}</span>
                <span v-else>永久有效</span>
            </div>
        </div>
        <div class="content" v-if="resultStatus && resultStatus.approvalResult == 2  && !flag">
            <img class="img1" src="@/assets/jujueshouquan.png">
            <span class="span1" style="margin-top:20px;">您的授权申请没有通过</span>
            <div class="reason">
                <span>驳回原因:{{resultStatus.reason}}</span>
            </div>
        </div>
        <div class="content" v-if="resultInfo && resultInfo.approvalResult == 0 && !flag">
            <img class="img1" src="@/assets/toexamine-icon.png">
            <span class="span1">您的企业实名认证正在审核中～</span>
            <span class="span2">请耐心等待,1~3个工作日完成审核</span>
        </div>
        
        <div class="content1" v-if="(resultInfo && resultInfo.approvalResult == 1) && !flag && !resultStatus">
            <div class="top-tit">
                <img src="@/assets/icon/op-qianshu.png" alt="">
                <span>恭喜您,成功完成了{{resultInfo.companyName}}的 企业认证</span>
            </div>
            <div class="info-flex">
                <span @click="gousePage">去签合同</span>
            </div>
            <!-- <div class="center-box">
                <div class="center-box-item1">您的企业印章:</div>
                <div class="center-box-item2">
                    <img :src="imageNo ? imageNo.previewPath : ''" alt="">
                </div>
                <div class="center-box-item3" @click="showClick">更换印章</div>
            </div> -->
        </div>
        <div class="content2" v-if="(resultInfo && resultInfo.approvalResult == 2) && !isFace && !flag">
            <img class="img1" src="@/assets/jujueshouquan.png">
            <span class="title1">由于如下原因,资料审核不通过</span>
            <div class="title2">
                <span>请核对</span>
                <span @click="goPage">重新认证</span>
            </div>
            <div class="reason">
                <span>驳回原因:{{resultInfo.reason}}</span>
            </div>
        </div>
        <van-popup v-model="show" position="bottom" :style="{ height: '60%' }">
            <div class="popup-content">
                <div class="popup-content-title">
                    印章列表
                </div>
                <div class="popup-content-top">
                    <div class="popup-content-top-item" v-for="(item,index) in imageList" :key="item.id" @click="itemClick(item,index)">
                        <img :src="item.previewPath" alt=""  :style="index === imgIndex ? {border:'1px solid #ccc'} : ''">
                    </div>
                </div>
                <div class="popup-content-btn">
                    <span @click="cencel">取消</span>
                    <span @click="confim">确定</span>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {selectSealList,faceResultSimple,updateSeal,saveCompanyAuthenticationInfo} from '@/util/api'
export default {
    data () {
        return{
            flag: '1',
            show: false,
            isFace: false,
            imageNo: null,
            imgIndex: '',
            imgItem: null,
            imageList: []
        }
    },
    computed:{
        ...mapState(['resultInfo','certificationInfo','signList','resultStatus','verifyToken'])
    },
    created () {
        console.log(this.certificationInfo,this.resultInfo,this.resultStatus);
        if((this.resultInfo && this.resultInfo.approvalResult == 1 && !this.$route.query.flag)){
            // this.selectSealList()
            // this.getSignatureToSignList()
        }
        this.flag = this.$route.query.flag
        this.isFace = this.$route.query.isFace
        if(this.isFace && this.certificationInfo){
            let data = {
                companyId: this.certificationInfo.companyId,
                legalRepresentative: this.certificationInfo.legalRepresentative,
                legalRepresentativeNo: this.certificationInfo.personNo,
                realnameWay: '2',
                image: '',
                imageUrl: ''
            }
            if (this.verifyToken) {
                faceResultSimple(this.verifyToken).then(res1 => {
                    data.imageUrl = res1
                    saveCompanyAuthenticationInfo(data).then(res =>{
                        console.log(res);
                    })
                })
            }
        }
    },
    methods: {
        selectSealList () {
            selectSealList({companyId:this.resultInfo.companyId}).then(res =>{
                console.log(res,'selectSealList');
                this.imageList = JSON.parse(JSON.stringify(res))
                this.imageList.map(item =>{
                    if(item.sealUse == 1){
                        this.imageNo = item
                    }
                })
            })
        },
        // getSignatureToSignList () {
        //     getSignatureToSignList().then(res =>{
        //         if(res){
        //             this.$store.commit('setSignList',res)
        //         }
        //     })
        // },
        showClick () {
            this.show = true
        },
        goPage () {
            this.$router.push({path:'/enterpriseCertification',query:{companyId:this.resultInfo.companyId}})
        },
        gousePage () {
            this.$router.push({path: '/usePage',query:{}})
            // this.$router.push({path: '/usePage',query:{companyId:this.resultInfo.companyId}})
        },
        goHome () {
            // this.getSignatureToSignList()
            this.$router.push({path: '/usePage',query:{companyId: this.resultStatus.companyId}})
        },
        itemClick (item,index) {
            this.imgIndex = index
            this.imgItem = item
        },
        confim () {
            if (this.imgItem && this.imgItem.id) {
                let companyId = this.resultInfo.companyId
                let data = {
                    id: this.imgItem.id,
                    companyId: companyId
                }
                updateSeal(data).then(res =>{
                    console.log(res);
                    this.show = false
                    this.selectSealList()
                })
            } else {
                this.$toast('请选取印章')
            }
        },
        cencel () {
            this.show = false
        },
        ...mapMutations(['setSignList'])
    }

}
</script>
<style lang="less" scoped>
.authenticationPage{
    width: 100%;
    height: 100%;
    background: #0067E1;
    display: flex;
    flex-direction: column;
    .top-title{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        font-size: 17px;
    }
    .content{
        width: 100%;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        .img1{
            width: 58px;
            height: 59px;
            margin-bottom: 10px;
        }
        .img2{
            width: 67px;
            height: 51px;
            margin-bottom: 10px;
        }
        .span1{
            font-size: 17px;
            color: #333;
            margin-bottom: 10px;
            font-weight: 700;
        }
        .span2{
            font-size: 16px;
            color: #A4A4A4;
            font-weight: 500;           
        }
        .info-flex1{
            width: 100%;
            display: flex;
            height: 30px;
            line-height: 30px;
            justify-content: flex-end;
            background: #fff;
            box-sizing: border-box;
            padding: 0 20px;
            span{
                text-decoration: underline;
                color: #0067E1;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
        }
        .title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            color: #30586C;
            padding: 0 20px;
            box-sizing: border-box;
            font-weight: bold;
        }
        .info-item1 {
            display: flex;
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            font-size: 15px;
            span {
                display: inline-block;
                min-width: 100px;
                color: #757B85;
                margin-right: 10px;
                &:nth-of-type(1){
                    text-align: right;
                }
                &:nth-of-type(2){
                    text-align: left;
                }
            }
        }
        .reason{
            width: 100%;
            display: flex;
            justify-content: center;
            span{
                width: 200px;
                font-size: 16px;
                padding: 10px 15px;
                min-height: 30px;
                background: #F5F5F5;
            }
        }
    }
    .content1{
        width: 100%;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        .top-tit{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            font-size: 16px;
            img{
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
        }
        .center-box{
            width: 100%;
            height: 300px;
            border: 1px solid #dcdcdc;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            .center-box-item1{
                width: 100%;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
                box-sizing: border-box;
            }
            .center-box-item2{
                width: 100%;
                height: 180px;
                display: flex;
                justify-content: center;
                img{
                    width: 180px;
                    height: 180px;
                }
            }
            .center-box-item3{
                width: 100%;
                flex: 1;
                align-items: center;
                color: #0067E1;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
                box-sizing: border-box;
            }
        }
    }
    .content2{
        width: 100%;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        box-sizing: border-box;
        .img1{
            width: 58px;
            height: 59px;
            margin-bottom: 30px;
        }
        .title1{
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            align-items: center;
        }
        .title2{
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            span{
                &:nth-of-type(2){
                    color: #0067E1;
                    cursor: pointer;
                }
            }
        }
        .reason{
            width: 100%;
            display: flex;
            justify-content: center;
            span{
                width: 200px;
                font-size: 16px;
                padding: 10px 15px;
                min-height: 30px;
                background: #F5F5F5;
            }
        }
    }
    .popup-content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .popup-content-title{
            width: 100%;
            height: 40px;
            font-size: 15px;
            text-align: center;
            line-height: 40px;
            border-bottom: 1px solid #dcdcdc;
        }
        .popup-content-top{
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            .popup-content-top-item{
                display: flex;
                padding: 20px;
                flex-direction: column;
                align-items: center;
                img{
                    width: 200px;
                    height: 200px;
                }
            }
        }
        .popup-content-btn{
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: space-around;
            align-items: center;;
            box-sizing: border-box;
            border-top: 1px solid #dcdcdc;
            span {
                width: 120px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                border-radius: 25px;

                &:nth-of-type(1) {
                    color: #666666;
                    border: 1px solid #999999;
                }

                &:nth-of-type(2) {
                    color: #fff;
                    background: linear-gradient(90deg, #0067E1, #77A3FF);
                }
            }
        }
    }
    .info-flex{
        width: 100%;
        display: flex;
        height: 30px;
        line-height: 30px;
        justify-content: flex-end;
        span{
            text-decoration: underline;
            color: #0067E1;
            font-size: 14px;
            cursor: pointer;
        }
    }
}
</style>